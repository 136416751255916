import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Esprit from './Components/Esprit'
import Photos from './Components/Photos'
import Tarifs from './Components/Tarifs'
import Contact from './Components/Contact'
import Grid from '@material-ui/core/Grid'
import poney from './pictures/poney.png'

import './main.scss'

class App extends Component {
    render() {
        return (
            <div className="App">
                <Header />
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid id="accueil" item xs={12} md={10} className="bgImg">
                        <Grid direction="column" style={{ marginTop: '-50vh' }}>
                            <div className="logotext">
                                <Grid container direction="row" justify='center'>
                                    <Grid item>
                                        <img className="logo" alt="logo" src={poney} />
                                    </Grid>
                                    <Grid item id="title">
                                        <h1
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                            }}
                                        >
                                            Landreau Equitation
                                        </h1>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        className="columnContent"
                        style={{ border: '4px solid black' }}
                    >
                        <Esprit />
                        <Tarifs />
                        <Contact />
                        <Photos />
                    </Grid>
                </Grid>
                <Footer />
            </div>
        )
    }
}

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)
