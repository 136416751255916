import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Scrollspy from 'react-scrollspy'
import Icon from '@material-ui/core/Icon'
import facebook from '../pictures/fb.png'

export default function Burger() {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <Icon>menu</Icon>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Scrollspy
                    items={['accueil', 'esprit', 'tarifs', 'contact', 'projets']}
                    offset={-300}
                    currentClassName="is-current"
                >
                    <a href="/#accueil">
                        <MenuItem
                            className="raleway menuLink"
                            onClick={handleClose}
                            href="#accueil"
                        >
                            <span>Accueil</span>
                        </MenuItem>
                    </a>
                    <a href="/#esprit">
                        <MenuItem className="raleway menuLink" onClick={handleClose} href="#esprit">
                            <span>A propos du club</span>
                        </MenuItem>
                    </a>
                    <a href="/#tarifs">
                        <MenuItem className="raleway menuLink" onClick={handleClose} href="#tarifs">
                            <span>Tarifs</span>
                        </MenuItem>
                    </a>
                    <a href="/#contact">
                        <MenuItem
                            className="raleway menuLink"
                            onClick={handleClose}
                            href="#contact"
                        >
                            <span>Contact</span>
                        </MenuItem>
                    </a>
                    <a href="/#projets">
                        <MenuItem
                            className="raleway menuLink"
                            onClick={handleClose}
                            href="#projets"
                        >
                            <span>Galerie photos</span>
                        </MenuItem>
                    </a>
                    <div className="burgerLogos">
                        <a
                            href="https://www.facebook.com/www.lelandreau.equitation/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="footerLogo" src={facebook} alt="facebook_logo" />
                        </a>
                    </div>
                </Scrollspy>
            </Menu>
        </div>
    )
}
