import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Gallery from 'react-photo-gallery'
import { photos } from '../photos'

import '../main.scss'

class Photos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    render() {
        return (
            <div id="projets">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ marginBottom: '10px' }}
                >
                    <svg className="center" width="80" height="20">
                        <line className="svgLine" x1="0" y1="0" x2="80" y2="0" />
                        <polygon className="svgTriangle" points="0,5 80,5 40,15" />
                    </svg>
                    <span className="title">Galerie Photos</span>
                    <iframe
                        title="Teaser du club"
                        src="https://www.youtube-nocookie.com/embed/kAEZHgZ9mSg"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="allowfullscreen"
                        style={{ height: '45vh', maxHeight: 315, width: '80vw', maxWidth: 560 }}
                    ></iframe>
                </Grid>
                <Gallery photos={photos} direction={'column'} />
            </div>
        )
    }
}

export default Photos
