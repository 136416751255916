import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import img1 from '../pictures/img/img1.png'
import img2 from '../pictures/img/img2.png'

import '../main.scss'

class Esprit extends Component {
    render() {
        return (
            <Grid container direction="column" justify="center" alignItems="center" id="esprit">
                <svg className="center" width="80" height="20">
                    <line className="svgLine" x1="0" y1="0" x2="80" y2="0" />
                    <polygon className="svgTriangle" points="0,5 80,5 40,15" />
                </svg>
                <span className="title">A propos du club</span>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={2} className="textAndImg" style={{ marginTop: '40px' }}>
                        <img
                            className="marginRight noPhone"
                            alt="logo"
                            src={img1}
                            style={{ width: '12vw' }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        className="textAndImg textPhone"
                        style={{ paddingRight: '15px' }}
                    >
                        <div className="logotext">
                            <span className="text">
                                <span className="text bold larger">
                                    Au Landreau Équitation nous proposons :
                                </span>
                                <li>
                                    un enseignement de l'équitation de loisirs ou de compétitions,
                                </li>
                                <li>des stages à chaques vacances scolaires,</li>
                                <li>des promenades à l'heure ou à la journée,</li>
                                <li>
                                    des week-ends thématiques autour des différentes disciplines
                                    équestres (CSO, dressage, voltige, équifun, équitation western,
                                    éthologie ...),
                                </li>
                                <li>l'équithérapie associée à la médiation animale,</li>
                                <li>un accueil pour vos sorties scolaires,</li>
                                <li>une ferme pédagogique.</li>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={2} className="textAndImg">
                        <img
                            className="marginLeft noPhone"
                            alt="logo"
                            src={img2}
                            style={{ width: '12vw' }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default Esprit
