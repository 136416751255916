import React, { Component } from 'react'
import Scrollspy from 'react-scrollspy'
import Burger from './Burger'
import poney from '../pictures/poney.png'

class Header extends Component {
    render() {
        return (
            <div className="header">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img className="headerLogo" src={poney} alt="facebook_logo" />
                    <a href="#accueil">
                        <span className="raleway headerText">Le Landreau Equitation</span>
                    </a>
                </div>
                <div id="fullMenu">
                    <Scrollspy
                        items={['accueil', 'esprit', 'tarifs', 'contact', 'projets']}
                        offset={-200}
                        currentClassName="is-current"
                    >
                        <a className="raleway menuLink" href="#accueil">
                            <span>Accueil</span>
                        </a>
                        <a className="raleway menuLink" href="#esprit">
                            <span>A propos du club</span>
                        </a>
                        <a className="raleway menuLink" href="#tarifs">
                            <span>Tarifs</span>
                        </a>
                        <a className="raleway menuLink" href="#contact">
                            <span>Contact</span>
                        </a>
                        <a className="raleway menuLink" href="#projets">
                            <span>Galerie photos</span>
                        </a>
                    </Scrollspy>
                </div>
                <div id="burgerMenu">
                    <Burger />
                </div>
            </div>
        )
    }
}

export default Header
