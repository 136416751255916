import React, { Component } from 'react'
import facebook from '../pictures/fb.png'

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <a
                    href="https://www.facebook.com/www.lelandreau.equitation/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className="footerLogo" src={facebook} alt="facebook_logo" />
                </a>
            </div>
        )
    }
}

export default Footer
