import img1 from './pictures/galeries/1.JPG'
import img2 from './pictures/galeries/2.JPG'
import img3 from './pictures/galeries/3.JPG'
import img4 from './pictures/galeries/4.JPG'
import img5 from './pictures/galeries/5.JPG'
import img6 from './pictures/galeries/6.JPG'
import img7 from './pictures/galeries/7.JPG'
import img8 from './pictures/galeries/8.JPG'
import img9 from './pictures/galeries/9.JPG' /*
import img10 from './pictures/galeries/10.jpg'
import img11 from './pictures/galeries/11.jpg'
import img12 from './pictures/galeries/12.jpg'*/

export const photos = [
    {
        src: img1,
        width: 4,
        height: 3,
        className: 'img',
    },
    {
        src: img2,
        width: 4,
        height: 3,
        className: 'img',
    },
    {
        src: img3,
        width: 4,
        height: 3,
        className: 'img',
    },
    {
        src: img4,
        width: 4,
        height: 3,
        className: 'img',
    },
    {
        src: img9,
        width: 4,
        height: 3,
        className: 'img',
    },
    {
        src: img6,
        width: 4,
        height: 3,
        className: 'img',
    },
    {
        src: img5,
        width: 4,
        height: 3,
        className: 'img',
    },
    {
        src: img7,
        width: 4,
        height: 3,
        className: 'img',
    },
    {
        src: img8,
        width: 4,
        height: 3,
        className: 'img',
    },
]
