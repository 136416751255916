import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'

import '../main.scss'

class Tarifs extends Component {
    render() {
        return (
            <Grid container direction="column" justify="center" alignItems="center" id="tarifs">
                <svg className="center" width="80" height="20">
                    <line className="svgLine" x1="0" y1="0" x2="80" y2="0" />
                    <polygon className="svgTriangle" points="0,5 80,5 40,15" />
                </svg>
                <span className="title">Tarifs</span>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <p className="text bold larger">Forfait annuel de Septembre à Juin :</p>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <span className="text">
                            <li>Né entre 2020 et 2017 → 560€</li>
                            <li>Né entre 2016 et 2011 → 620€</li>
                            <li>Né entre 2010 et 2006 → 660€</li>
                            <li>Né en 2005 et avant → 690€</li>
                        </span>
                        <p className="text">
                            La licence FFE est à régler à part à l'inscription :<br />
                            - Moins de 18 ans : 25€<br />
                            - Plus de 18 ans : 36€.
                        </p>
                        <p className="text">
                            L'adhésion à l'association du club est obligatoire: 30€ par inscrit, à régler à part à l'inscription.
                        </p>
                        <p className="text">
                            Le forfait comprend les heures de cours. Il est payable en 3 ou 10
                            fois.
                        </p>
                        <p className="text">
                            Il n'y a pas de cours pendant les vacances scolaires.<br />
                            Les cours des samedis précédant les vacances scolaires sont maintenus, ainsi que les jours fériés.
                        </p>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <p className="text bold larger">Les cours &#8220;à la carte&#8221; :</p>
                        <p className="text">
                            A partir de 16 ans seulement
                        </p>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <span className="text">
                            <li>Carte de 8 heures → 195€ (valable 4 mois, adhésion à l'association et licence non incluses <b>obligatoires</b>)</li>
                            <li>1 heure passagère (en cours collectif) → 30€</li>
                            <li>1 séance particulière avec moniteur → 50€</li>
                            <li>
                                Mini-promenade (3-9 ans) en autonomie de 30 minutes → 12€<br />
                                (Si besoin d'un accompagnateur, un supplément sera demandé)
                            </li>
                        </span>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <p className="text bold larger">Le Baby-Poney est à partir de 3 ans</p>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <p className="text bold larger">Stage Journée (pas de stage en Juillet et Août) :</p>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <span className="text">
                            <li>Demi-journée → 30€</li>
                            <li>Journée → 50€</li>
                            <li>Semaine complète de 5 demi-journées → 140€</li>
                            <li>Le goûter est offert.</li>
                        </span>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <p className="text bold larger">Possibilité de cartes cadeaux</p>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default Tarifs
